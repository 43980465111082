import React, { useState, useEffect } from 'react';
import { 
  ChevronDown, 
  ChevronUp, 
  Menu, 
  X, 
  Brain, 
  Eye, 
  Shield, 
  Users, 
  Building2,
  Factory,
  Microscope,
  Car,
  GraduationCap,
  ArrowRight,
  Zap,
  BarChart3,
  Camera,
  Lightbulb,
  Play,
  ShoppingBag,
  Building,
  Check
} from 'lucide-react';

const HeaderComp = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
  
    useEffect(() => {
      const handleScroll = () => {
        setIsScrolled(window.scrollY > 20);
      };
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);
  
    const navItems = [
      { name: 'Solutions', href: '#solutions' },
      { name: 'Industries', href: '#industries' },
      { name: 'Expertise', href: '#expertise' },
      { name: 'Projects', href: '#projects' },
      { name: 'Contact', href: '#contact' }
    ];
  
    return (
        <header 
          className={`fixed w-full z-50 transition-all duration-300 ${
            isScrolled ? 'bg-white/90 backdrop-blur-md shadow-md' : 'bg-transparent'
          }`}
        >
          <div className="container mx-auto px-4 py-2">
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                {/* Large logo container */}
               
              </div>
              
              <nav className="hidden lg:flex items-center space-x-8">
                {navItems.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={`relative group py-2 ${
                      isScrolled ? 'text-gray-800' : 'text-white'
                    }`}
                  >
                    <span className="relative z-10">{item.name}</span>
                    <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-blue-600 group-hover:w-full transition-all duration-300"></span>
                  </a>
                ))}
                <button 
                  onClick={() => document.getElementById('contact').scrollIntoView({ behavior: 'smooth' })}
                  className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition-colors duration-300">
                  Get Started
                </button>
              </nav>
              
              <button 
                className="lg:hidden z-50"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                {isMenuOpen ? <X className="text-gray-800" /> : <Menu className={isScrolled ? 'text-gray-800' : 'text-white'} />}
              </button>
            </div>
          </div>
          
          {/* Mobile Menu */}
          <div className={`fixed inset-0 bg-white/95 backdrop-blur-md transition-transform duration-300 lg:hidden ${
            isMenuOpen ? 'translate-x-0' : 'translate-x-full'
          }`}>
            <div className="container mx-auto px-4 py-20">
              <nav className="flex flex-col space-y-6">
                {navItems.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="text-2xl font-semibold text-gray-800 hover:text-blue-600 transition-colors duration-300"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    {item.name}
                  </a>
                ))}
                <button 
                  onClick={() => document.getElementById('contact').scrollIntoView({ behavior: 'smooth' })}
                  className="bg-blue-600 text-white px-8 py-3 rounded-full hover:bg-blue-700 transition-colors duration-300 text-lg">
                  Get Started
                </button>
              </nav>
            </div>
          </div>
        </header>
    );
}

export default HeaderComp;
